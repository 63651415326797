import { BoxProps, IconProps } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

import type { PublicUser, Lang, CountryCode } from '@miimosa/common/types'
import { usePlatform } from '@miimosa/design-system/lib/hooks'
import { toV2Link } from '@miimosa/design-system/lib/hooks/use_v2_link'

import DonationIcon from './icons/Donation'
import LendingIcon from './icons/Lending'

export const headerHeight = '72px'

export interface NavLink {
  text: string
  href?: string
  route?: string
  withBrush?: boolean
}

interface CommonNavItem extends BoxProps {
  dotColor?: string
  markColor?: string
  links?: NavLink[]
  route: string
  rightDropdown?: boolean
  Icon?: FC<IconProps>
}

export interface NavItem extends CommonNavItem {
  label: string
  description: string
}

interface Options {
  t: (k: string) => string
  lang: Lang
  platform: CountryCode
}

// fixHref converts to a v2 link if required
function fixHref(href: string | undefined, options: Options): string | undefined {
  if (!href || href.startsWith('http')) return href
  return toV2Link(href, options.platform, options.lang)
}

function makeNavItem(options: Options, prefix: string, data: CommonNavItem): NavItem {
  return {
    label: options.t(prefix + 'title'),
    description: options.t(prefix + 'description'),
    ...data,
    ...(data.links && {
      links: data.links.map((l) => ({
        text: options.t(prefix + l.text),
        route: l.route,
        href: fixHref(l.href, options),
        withBrush: l.withBrush,
      })),
    }),
  }
}

const BASE_DONATION_LINKS: NavLink[] = [
  { text: 'projects', route: '/projects?type=donation', withBrush: true },
  { text: 'noel', route: '/noel?type=donation' },
  { text: 'help', href: '/help_center#contributors' },
]

const BASE_LENDING_LINKS: NavLink[] = [
  // Same as donation
  { text: 'projects_lending', route: '/projects?type=lending', withBrush: true },
  // { text: 'map', href: '/map' },
  { text: 'testimonies', route: '/testimony' },
  { text: 'explain', route: '/invest' },
  { text: 'stats', route: '/lending_stats' },
  { text: 'test', href: '/lending_capacity_simulator' },
]

export function useNavItems(user?: PublicUser): NavItem[] {
  const { t } = useTranslation('common', { keyPrefix: 'header.nav' })
  const platform = usePlatform()
  const opts = { t, ...platform }

  return [
    makeNavItem(opts, 'donation.', {
      Icon: DonationIcon,
      dotColor: '#00B077',
      ml: '0',
      links: BASE_DONATION_LINKS,
      route: '/projects?type=donation',
    }),
    makeNavItem(opts, 'lending.', {
      Icon: LendingIcon,
      dotColor: '#638DFF',
      links: BASE_LENDING_LINKS,
      route: '/projects?type=lending',
    }),
    makeNavItem(opts, 'finance.', {
      route: '/create',
      rightDropdown: true,
      markColor: '#FF6C4A',
      ml: 'auto',
    }),
    ...(user
      ? []
      : [
          makeNavItem(opts, 'manifest.', {
            route: '/manifest',
            rightDropdown: true,
          }),
        ]),
  ]
}
